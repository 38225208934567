// src/components/Card.js
import React, { useState } from "react";
import "./Card.css";

const Card = ({ title, content, image, extraContent, skills, githubUrl }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div
      className={`card ${isExpanded ? "expanded" : ""}`}
      onClick={handleToggle}
    >
      <div className="card-content-container">
        <img src={image} alt={title} className="card-image" />
        <div className="card-details">
          <h2 className="card-title">{title}</h2>
          <p className="card-skills">Skills: {skills}</p>
          <p className="card-content">{content}</p>
        </div>
      </div>
      {isExpanded && (
        <div className="card-extra-content">
          {extraContent}
          {githubUrl && (
            <a
              href={githubUrl}
              className="card-github-link"
              onClick={(e) => e.stopPropagation()} // Prevents the card toggle when clicking the link
              target="_blank"
              rel="noopener noreferrer"
            >
              View on GitHub
            </a>
          )}
        </div>
      )}
    </div>
  );
};

export default Card;
