// Contact.js
import React, { useState, useEffect } from "react";
import "./Contact.css";
import { db } from "./firebase"; // Import the Firestore database
import { collection, addDoc } from "firebase/firestore"; // Import Firestore methods

function Contact() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsVisible(true);
    }, 500); // Delay in milliseconds
    return () => clearTimeout(timeoutId);
  }, []);

  const [formData, setFormData] = useState({
    name: "Avi Nimni",
    email: "Avi.Nimni@TelAvivo.com",
    message:
      "Hi there, I really liked your web site so I want to transfer all my money to you",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form Data Submitted:", formData);
    try {
      await addDoc(collection(db, "messages"), formData); // Add form data to Firestore
      alert("Message sent successfully!");
      setFormData({ name: "", email: "", message: "" }); // Reset form
    } catch (error) {
      console.error("Error adding document: ", error);
      alert("Error sending message. Please try again.");
    }
  };

  return (
    <div className={`contact-container ${isVisible ? "fade-in" : ""}`}>
      <h2>Contact Us</h2>
      <form onSubmit={handleSubmit} className="contact-form">
        <label htmlFor="name">Name</label>
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
        />
        <label htmlFor="email">Email</label>
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />
        <label htmlFor="message">Message</label>
        <textarea
          id="message"
          name="message"
          value={formData.message}
          onChange={handleChange}
          required
        />
        <button type="submit">Send</button>
      </form>
    </div>
  );
}

export default Contact;
