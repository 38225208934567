import React, { useEffect, useState } from "react";
import Card from "./components/Card";
import Brain1 from "./assets/screan shots/Brain1.png";
import lifemaze from "./assets/screan shots/lifemazelogo.png";
import SS_logo from "./assets/Supply Solutions logo.jpg";
import data_photo from "./assets/screan shots/data project.png";
import pacman from "./assets/screan shots/Pacman.png";
import lifemaze1 from "./assets/screan shots/lifemaze1.jpeg";
import lifemaze2 from "./assets/screan shots/lifemaze2.jpeg";
import lifemaze3 from "./assets/screan shots/lifemaze3.jpeg";
import pacman1 from "./assets/screan shots/pacman1.png";
import pacman2 from "./assets/screan shots/pacman2.png";
import braingu1 from "./assets/screan shots/braingurion 1.jpeg";
import braingu2 from "./assets/screan shots/brain guion2.jpeg";
import braingu3 from "./assets/screan shots/braain gurion3.jpeg";
import dataX1 from "./assets/screan shots/dataX.png";
import dataX2 from "./assets/screan shots/dataX2.png";
import supsol1 from "./assets/screan shots/supsol1.png";
import supsol2 from "./assets/screan shots/supsol2.png";
import portf from "./assets/screan shots/portfolio.png";
import "./Home.css";

function Home() {
  const [isVisible, setIsVisible] = useState(Array(7).fill(false));

  useEffect(() => {
    const timeouts = isVisible.map(
      (_, index) =>
        setTimeout(() => {
          setIsVisible((prev) => {
            const newVisibility = [...prev];
            newVisibility[index] = true;
            return newVisibility;
          });
        }, index * 500) // 500ms delay between each card
    );

    return () => {
      timeouts.forEach((timeout) => clearTimeout(timeout));
    };
  }, []);

  const cardsData = [
    {
      title: "LifeMaze",
      content:
        "A browser game developed by Bar Almog Hadar and me. Designed to provide veterans with tools to navigate the challenges of adult life in an engaging and humorous manner",
      skills: "JavaScript, HTML, CSS, Node.js, MySQL",
      extraContent: (
        <div>
          <p>
            During my second year in college as part of a software project
            management course, We decided like suckers to jump over the
            bellybutton and make a game from a first person angle like doom but
            without the sooting. We used a technology we did not know and my
            main role was to build the backend. It is important to note that we
            met the task without gpt chat
          </p>
          <p>Here are some screenshots from the game:</p>
          <img src={lifemaze1} alt="LifeMaze 1" />
          <p>Login screen</p>
          <img src={lifemaze2} alt="LifeMaze 2" />
          <p>
            A hike through the horrors of the world on the way to finding an
            apartment
          </p>
          <img src={lifemaze3} alt="LifeMaze 3" />
          <p>Real challenges in this life</p>
        </div>
      ),
      image: lifemaze,
      githubUrl: "https://github.com/alexsavizky/Life_Maze",
    },
    {
      title: "Data Project X",
      content:
        "A data science project in which we developed a decision tree and naive bayes and use ready-made classification tools such as k mean and knn in order to classify edible and poisonous mushrooms",
      image: data_photo,
      githubUrl: "https://github.com/alexsavizky/Data-Project-X",
      skills: "Python, Jupiter, Basic Data Analysis and Classification",
      extraContent: (
        <div>
          <p>
            One of the projects I am most proud of mainly because of the
            mathematical difficulty in developing the models ourselves.
          </p>
          <img src={dataX1} alt="Data Project X 1" />
          <p>Here is the main page</p>
          <img src={dataX2} alt="Data Project X 2" />
          <p>We got some crazy results </p>
        </div>
      ),
    },
    {
      title: "BrainGurion",
      skills:
        "Python, Brain–computer interface (BCI), Frequency Classification, Stay awake for more than 24 hours",
      content:
        "My team and I developed a product for careful driving, we transmitted a frequency from a ''phone'' (everything happens on the computer screen, but for the sake of the simulation) and actually with the help of the BCI we were able to detect when a person is concentrating too much on the frequency of the phone and not on the road.",
      image: Brain1,
      extraContent: (
        <div>
          <p>
            Perhaps one of the most significant experiences I had as a student
            was participating in this hackathon of the neuroscience department
            of Ben Gurion University.
          </p>
          <p>
            All the groups received a brain-computer interface. We read the
            signals of my brain (during the hackathon the doctors discovered
            that I was missing a n alpha wave and wanted to investigate me) we
            took these signals and trained a classification model on them that
            recognized when I was concentrating on a frequency of 15 Hz that we
            ran on a phone screen and that's how we trained A model on my brain
            and it was possible according to my brain to recognize when I lose
            concentration on the road.
          </p>
          <img src={braingu1} alt="BrainGurion 1" />
          <p>Here I am with the BCI on, at 2 am working my ass off</p>
          <img src={braingu2} alt="BrainGurion 2" />
          <p>
            Here we demonstrate the final product after 25 hours of continuous
            work (well that's a lie because there were breaks for food and
            treats and a nap during the preparation of the presentation)
          </p>
          <img src={braingu3} alt="BrainGurion 3" />
          <p>Here is the simulation</p>
        </div>
      ),
    },
    {
      title: "Pacman",
      skills: "C, Pain tolerance, Low self-worth",
      content:
        "The classic Pacman game with the ''amazing'' graphics of the C console",
      image: pacman,
      githubUrl: "https://github.com/alexsavizky/Data-Project-X",
      extraContent: (
        <div>
          <p>
            Developed entirely in C, this version of Pacman brings the nostalgic
            charm of the original arcade game to life with a modern twist. While
            the graphics are rendered in the simplistic style of the C console,
            they capture the essence of the classic gameplay, making every chase
            and escape thrilling. The game's logic, from the predictable ghost
            patterns to the exhilarating power-up moments, has been meticulously
            coded to provide an authentic Pacman experience.
          </p>
          <p>Here are some screenshots from the game:</p>
          <img src={pacman1} alt="Pacman 1" />
          <p>Game start: the &lt; is the player and the X's are the bad guys</p>
          <img src={pacman2} alt="Pacman 2" />
          <p>
            here i took the magic bary and the bad guys became Y and runing from
            me bucease now i can catch them
          </p>
        </div>
      ),
    },
    {
      title: "Supply Solutions",
      content:
        "This is a Python-based warehouse management system designed for the Department of Visual Communication at Sami Shimon College. The system allows users to manage inventory, track orders, and streamline the overall warehouse operations.",
      image: SS_logo,
      githubUrl: "https://github.com/alexsavizky/Supply-Solutions",
      skills: "Python, Flask, MySQL",
      extraContent: (
        <div>
          <p>
            The system features a robust inventory management module that allows
            users to categorize and catalog items with detailed descriptions,
            quantities, and locations within the warehouse. Advanced search and
            filter functions make it easy to locate specific items quickly. The
            order tracking functionality ensures that all incoming and outgoing
            orders are logged, with real-time updates on order status, from
            initial request to final delivery. Additionally, the system includes
            reporting tools that generate comprehensive reports on inventory
            levels, order history, and warehouse performance metrics, aiding in
            data-driven decision-making. The intuitive design and automation
            capabilities reduce manual workload, enhance accuracy, and
            significantly streamline warehouse operations, making it an
            indispensable asset for the department.
          </p>
          <img src={supsol1} alt="supsol1" />
          <img src={supsol2} alt="supsol2" />
        </div>
      ),
    },
    {
      title: "Alex portfolio",
      content: "A website that shows the positive qualities in me.",
      image: portf,
      githubUrl: "https://github.com/alexsavizky/Portfolio2",
      skills: "React, Javascript, CSS, HTML, Firebase",
      extraContent: (
        <div>
          <p>Beware of stack overflow</p>
        </div>
      ),
    },
  ];

  return (
    <div>
      <h1 className={isVisible[0] ? "fade-in" : ""}>
        Hi, I'm Alex.
        <br />a Software Engineer.
      </h1>
      <h4 className={isVisible[1] ? "fade-in" : ""}>
        Bringing a touch of creativity to every project,
        <br />I blend technical expertise with a good sense of humor to create
        innovative solutions.
      </h4>
      <div className="cards-container">
        {cardsData.map((card, index) => (
          <div
            key={index}
            className={`card-wrapper ${isVisible[index] ? "fade-in" : ""}`}
          >
            <Card
              title={card.title}
              content={card.content}
              image={card.image}
              skills={card.skills}
              extraContent={card.extraContent}
              githubUrl={card.githubUrl}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default Home;
