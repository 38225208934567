import React, { useEffect, useState } from "react";
import "./About.css";
import alexpro from "./assets/alex photo.jpeg";

function About() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsVisible(true);
    }, 500); // delay to start the animation
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <div className="about-container">
      <div className={`about-image ${isVisible ? "fade-in" : ""}`}>
        <img src={alexpro} alt="Profile" />
      </div>
      <div className="about-content">
        <h2 className={isVisible ? "fade-in" : ""}>About Me</h2>
        <p className={isVisible ? "fade-in" : ""}>
          Hi, I'm Alexander Savitsky, but everyone calls me Alex. I am a
          software engineering student in the data science track in the fourth
          year. But after all, I'm A child in an adult's body looking for new
          things to explore and learn all day long. Among my favorite things in
          the world is learning new things and challenging myself in this field.
          On this website you can see projects that I have done and even though
          part of them are mandatory exercises as part of my studies, I feel
          that I have invested and given a little more than what was formally
          required of me. Sometimes it was to show that I was capable and
          sometimes it was just for the laughs. (An hour before submitting a
          project, I will have to learn how to paste my face and kanye west's
          face in Photoshop to the background of a website I have to submit).
        </p>
        <br></br>
        <p className={isVisible ? "fade-in" : ""}>
          One of my other loves in life is music and I have quite an obsession
          with this subject. I have been playing the guitar for more than a
          decade and I learned this with a teacher, but recently I learned how
          to play bass drums and keyboards on my own (I currently perform in a
          band as a drummer) in addition, I am learning independently about
          music production and working on personal projects as well as
          collaborations with musician friends. As part of the profession I
          chose and the love for music, I am currently working on a final
          project that will deal with the creation of music by machine learning.
          In fact, you can see more explanation about the project on the
          portfolio page.
        </p>

        <p className={isVisible ? "fade-in" : ""}>
          Key computing skills :C C++ C# Java Python JavaScript React HTML CSS
          Node.js Assembly MySQL Git
          <br></br> <br></br>Music Production softwares : Ableton Cubase
        </p>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
      </div>
    </div>
  );
}

export default About;
