// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAFUY5wFQp-zRMPVEngw7ety0uErBBZEPA",
  authDomain: "portfolio-68a61.firebaseapp.com",
  projectId: "portfolio-68a61",
  storageBucket: "portfolio-68a61.appspot.com",
  messagingSenderId: "540629994673",
  appId: "1:540629994673:web:4c9bcf5fd0ae06be1d2690",
  measurementId: "G-2M0N8SQ0M1",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

export { db };
